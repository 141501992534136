


























































































import { Component, Vue } from 'vue-property-decorator';
import NewsTableCard from '@/components/table/NewsTableCard.vue';
import { NewsPageOverview, NewsPageStatus } from '@/apis/newsapi';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sorting';
import { Filter } from '@/assets/types/types';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FilterButtonBar from '@/components/partials/FilterButtonBar.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import SearchBar from '@/components/partials/SearchBar.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import ProjectTableCard from '@/components/table/ProjectTableCard.vue';
import Table from '@/components/table/Table.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import ButtonType from '@/assets/buttonTypes';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    NewsTableCard,
    CustomSelect,
    DeleteDialog,
    RoundedButtonFilled,
    TextHeader,
    SearchBar,
    FilterButtonBar,
    ProjectTableCard,
    Table,
    IconInCircle,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ProjectManagement extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private SortBy = Sort;
  private isLoading = true;
  private showUnexpectedErrorMessage = false;
  private noItemsFoundMessage: string = 'Sie haben bisher noch keine Beiträge hinzugefügt oder ihre Suche ergab keine Treffer.'

  private role: Role = Role.DEVELOPER;
  private userId!: string;

  private showDeleteDialog = false;
  private deleteDialogMessage: string = '';
  private projectToDeleteUuid: string | undefined = undefined;

  private getDeleteMessage(projectName: string): string {
    return `Soll die News-Seite "${projectName}" wirklich gelöscht werden?`;
  }

  private filterValue: Filter = undefined;
  private get filterValueGetter() {
    return this.filterValue;
  }

  private sortOptions: Option[] = [
    {
      name: 'Veröffentlichungsdatum (ab)',
      value: Sort.DATE_DESCENDING,
    },
    {
      name: 'Veröffentlichungsdatum (auf)',
      value: Sort.DATE_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
  ];

  private sortValue: Option = {
    name: 'Veröffentlichungsdatum (ab)',
    value: Sort.DATE_DESCENDING,
  };

  private searchTerm: string = '';

  private selectedStateFilters: Array<'published' | 'waitingForStart' | 'inProgress'> = [];

  private currentPage: number = 0;
  private totalPages: number = 0;
  private itemsPerPage: number = 10;
  private totalNumberOfItems: number = 0;

  private isAdmin: boolean = true;

  private editIcons = {
    edit: require('@/assets/images/icons/pencil-1.svg'),
    delete: require('@/assets/images/icons/bin.svg'),
  };

  private publishStates = {
    published: {
      name: 'published',
      text: 'Veröffentlicht',
      src: require('@/assets/images/check-1.png'),
      color: 'green-500',
      apiRequestValue: NewsPageStatus.Published,
    },
    waitingForStart: {
      name: 'waitingForStart',
      text: 'Wartet auf Start',
      src: require('@/assets/images/icons/question-circle.svg'),
      color: 'primary-blue',
      apiRequestValue: NewsPageStatus.WaitingToPublish,
    },
    inProgress: {
      name: 'inProgress',
      text: 'In Bearbeitung',
      src: require('@/assets/images/icons/pencil-1.svg'),
      color: 'primary-blue',
      apiRequestValue: NewsPageStatus.Editing,
    },
  };

  private news: NewsPageOverview[] = [];
  private developerIdToCompanyNameMap: Map<string, string> = new Map<string, string>();

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private get rows() {
    return this.news.map((project) => ({
      state: this.mapResponsePublishStateEnumToPublishState(project.status as NewsPageStatus),
      projectUuid: project.uuid,
      developerId: project.lastEditingUserId,
      company: '',
      name: project.title,
      publishDate: this.formatDate(project.publishDate as string),
    }));
  }

  private formatDate(dateString: string) {
    if (!dateString) return '-';
    return `${this.dateTimeFormatter.format(new Date(Date.parse(dateString)))} Uhr`;
  }

  private getLastEditorName(developerId: string) {
    console.log(developerId);
    const name: string | undefined = this.developerIdToCompanyNameMap.get(developerId);
    console.log(name);
    return name === undefined ? '-' : name;
  }

  private async getCompanyName(developerId: string) {
    let companyName: string = '';
    try {
      if (this.isAdmin) {
        const companyInformationResponse = await Services.users.getUserByUserId(developerId);
        const resultData = companyInformationResponse.data;
        return `${resultData.firstname} ${resultData.lastname}`;
      }
    } catch (e) {
      companyName = '';
    }
    return companyName;
  }

  private get publishStateFiltersForRequest(): NewsPageStatus[] {
    return this.selectedStateFilters.map((s) => this.publishStates[s].apiRequestValue);
  }

  private mapResponsePublishStateEnumToPublishState(state: NewsPageStatus) {
    switch (state) {
      case NewsPageStatus.Published:
        return this.publishStates.published;
      case NewsPageStatus.WaitingToPublish:
        return this.publishStates.waitingForStart;
      default:
        return this.publishStates.inProgress;
    }
  }

  private get currentItemStart(): number {
    return this.currentPage * this.itemsPerPage + 1;
  }

  private get currentItemEnd(): number {
    return this.currentItemStart + this.news.length - 1;
  }

  public async mounted(): Promise<void> {

    try {
      const role: Role = this.mapRoleStringToEnumValue(this.$store.state.role);
      this.role = role;
      this.isAdmin = role === Role.ADMIN;

      if (this.role === Role.DEVELOPER) {
        this.userId = this.$store.state.userId;
      }

      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);

      if (this.isAdmin && this.news.length > 0) {
        await this.loadAllCompanyNames();
      }
    } catch (e) {
      this.news = [];
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.isLoading = false;
    }
  }

  private async loadAllCompanyNames() {

    const promises = [];
    const processedDeveloperIds: string[] = Array.from(this.developerIdToCompanyNameMap.keys());

    for (let i = 0; i < this.news.length; i += 1) {

      const developerId: string = this.news[i].lastEditingUserId as string;

      if (developerId && !processedDeveloperIds.includes(developerId)) {
        promises.push(this.loadCompanyName(developerId));
        processedDeveloperIds.push(developerId);
      }
    }

    await Promise.all(promises);
  }

  private async loadCompanyName(developerId: string) {
    if (developerId && developerId !== '' && !this.developerIdToCompanyNameMap.has(developerId)) {
      const companyName: string = await this.getCompanyName(developerId);
      this.developerIdToCompanyNameMap.set(developerId as string, companyName);
    }
  }

  private mapRoleStringToEnumValue(role: string): Role {
    switch (role) {
      case 'User':
        return Role.USER;
      case 'Developer':
        return Role.DEVELOPER;
      case 'Admin':
        return Role.ADMIN;
      default:
        return Role.NOROLE;
    }
  }

  private editProject(id: string) {
    this.$router.push({
      name: 'NewsEditor',
      params: {
        newsUUID: id,
      },
    });
  }

  private deleteProject(id: string, name: string) {
    this.deleteDialogMessage = this.getDeleteMessage(name);
    this.showDeleteDialog = true;
    this.projectToDeleteUuid = id;
  }

  private async deleteProjectConfirmed() {
    this.showDeleteDialog = false;

    if (this.projectToDeleteUuid) {
      const result = await Services.news.deleteNewsPage(this.projectToDeleteUuid);

      if (result.status === 204) {
        this.news = this.news.filter((p) => p.uuid !== this.projectToDeleteUuid);
      }

      this.projectToDeleteUuid = undefined;
      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private deleteProjectCanceled() {
    this.showDeleteDialog = false;
    this.projectToDeleteUuid = undefined;
  }

  private async searchNews(pageNumber: number = 0, searchTerm: string, filterBy: Filter, sortBy: Option): Promise<NewsPageOverview[]> {
    try {
      const response = await Services.news.getAllNews(pageNumber, this.itemsPerPage, searchTerm, this.publishStateFiltersForRequest, sortBy.value.value, sortBy.value.order);
      this.currentPage = response.data.currentPage as number;
      this.totalNumberOfItems = response.data.totalItemsFound as number;
      this.totalPages = response.data.totalPages as number;

      try {
        if (searchTerm.trim()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$matomo.trackSiteSearch(searchTerm, 'news table', response.data.totalItemsFound);
        }
      } catch (e) {
        // do nothing
      }

      return response.data.newsPages as NewsPageOverview[];

    } catch (e) {
      console.log(e);
      return [];
    }
  }

  private async publishStateSelectionChanged(selectedStateFilters: ('published' | 'waitingForStart' | 'inProgress')[]) {
    this.selectedStateFilters = selectedStateFilters;
    this.currentPage = 0;
    this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async sortValueChanged() {
    this.currentPage = 0;
    this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async filterValueChanged(filterValue: Filter) {
    this.currentPage = 0;
    this.filterValue = filterValue;
    this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async searchButtonClicked(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async itemsPerPageChanged(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async toFirstPage() {
    if (this.currentPage > 0) {
      this.currentPage = 0;
      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async previousPage() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async nextPage() {
    if (this.currentItemEnd < this.totalNumberOfItems) {
      this.currentPage += 1;
      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async toLastPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage = this.totalPages - 1;
      this.news = await this.searchNews(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

}
