




















































































































import { Component, Vue } from 'vue-property-decorator';
import { ProjectOverviewRestDto, PublishTypeEnum } from '@/apis/projectapi';
import ButtonType from '@/assets/buttonTypes';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sorting';
import { Filter } from '@/assets/types/types';
import DeleteDialog from '@/components/DeleteDialog.vue';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import FilterButtonBar from '@/components/partials/FilterButtonBar.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import SearchBar from '@/components/partials/SearchBar.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import ProjectTableCard from '@/components/table/ProjectTableCard.vue';
import Table from '@/components/table/Table.vue';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    CustomSelect,
    DeleteDialog,
    RoundedButtonFilled,
    TextHeader,
    SearchBar,
    FilterButtonBar,
    ProjectTableCard,
    Table,
    IconInCircle,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ProjectManagement extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private SortBy = Sort;
  private isLoading = true;
  private showUnexpectedErrorMessage = false;
  private noItemsFoundMessage: string = 'Sie haben bisher noch keine Apps & Projekte hinzugefügt oder ihre Suche ergab keine Treffer.';

  private role: Role = Role.DEVELOPER;
  private userId!: string;

  private showDeleteDialog = false;
  private deleteDialogMessage: string = '';
  private projectToDeleteUuid: string | undefined = undefined;
  private filterValue: Filter = undefined;
  private sortValue: Option = {
    name: 'Erscheinungsdatum (ab)',
    value: Sort.DATE_DESCENDING,
  };
  private sortOptions: Option[] = [
    {
      name: 'Erscheinungsdatum (ab)',
      value: Sort.DATE_DESCENDING,
    },
    {
      name: 'Erscheinungsdatum (auf)',
      value: Sort.DATE_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
  ];
  private searchTerm: string = '';
  private selectedStateFilters: Array<'Published' | 'Rejected' | 'Editing' | 'InReview'> = [];
  private currentPage: number = 0;
  private totalPages: number = 0;
  private itemsPerPage: number = 10;
  private totalNumberOfItems: number = 0;
  private isAdmin: boolean = true;
  private appTypeIcons = {
    ios: require('@/assets/images/icons/os-system-apple.svg'),
    android: require('@/assets/images/icons/android-1.svg'),
    web: require('@/assets/images/icons/network-navigation.svg'),
    project: require('@/assets/images/icons/checklist.svg'),
  };
  private editIcons = {
    edit: require('@/assets/images/icons/pencil-1.svg'),
    delete: require('@/assets/images/icons/bin.svg'),
  };
  private publishStates = {
    Published: {
      name: 'Published',
      text: 'Veröffentlicht',
      icon: 'icon-check-circle',
      cssClasses: 'w-7 h-7 text-green-500 rounded-full mr-2',
      apiRequestValue: PublishTypeEnum.Published,
    },
    Rejected: {
      name: 'Rejected',
      text: 'Abgelehnt',
      icon: 'icon-alert-circle',
      cssClasses: 'w-6 h-6 p-1 bg-red-500 text-white rounded-full mr-2',
      apiRequestValue: PublishTypeEnum.Rejected,
    },
    InReview: {
      name: 'InReview',
      text: 'In Prüfung',
      icon: 'icon-question-circle',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full mr-2',
      apiRequestValue: PublishTypeEnum.InReview,
    },
    Editing: {
      name: 'Editing',
      text: 'In Bearbeitung',
      icon: 'icon-edit',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full mr-2',
      apiRequestValue: PublishTypeEnum.Editing,
    },
  };

  private projects: ProjectOverviewRestDto[] = [];
  private developerIdToCompanyNameMap: Map<string, string> = new Map<string, string>();
  private dateTimeFormatter = DateService.dateTimeFormatter;

  private get filterValueGetter() {
    return this.filterValue;
  }

  private get rows() {
    return this.projects.map((project) => ({
      state: this.mapResponsePublishStateEnumToPublishState(project.publishState as PublishTypeEnum),
      projectId: project.id,
      projectUuid: project.uuid,
      developerId: project.developerId,
      company: '',
      name: project.name,
      types: {
        android: project.hasAndroidApp,
        ios: project.hasIOSApp,
        webapp: project.hasWebApp,
        project: !project.hasAndroidApp && !project.hasIOSApp && !project.hasWebApp,
      },
      publishDate: `${this.dateTimeFormatter.format(new Date(Date.parse(project.updatedAt as string)))} Uhr`,
      likes: project.likeCount,
    }));
  }

  private get publishStateFiltersForRequest(): PublishTypeEnum[] {
    return this.selectedStateFilters.map((s) => this.publishStates[s].apiRequestValue);
  }

  private get currentItemStart(): number {
    return this.currentPage * this.itemsPerPage + 1;
  }

  private get currentItemEnd(): number {
    return this.currentItemStart + this.projects.length - 1;
  }

  private async mounted() {
    try {
      const role: Role = this.mapRoleStringToEnumValue(this.$store.state.role);
      this.role = role;
      this.isAdmin = role === Role.ADMIN;

      if (this.role === Role.DEVELOPER) {
        this.userId = this.$store.state.userId;
      }

      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);

      if (this.isAdmin && this.projects.length > 0) {
        await this.loadAllCompanyNames();
      }
    } catch (e) {
      this.projects = [];
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.isLoading = false;
    }
  }

  private getDeleteMessage(projectName: string): string {
    return `Soll die App "${projectName}" wirklich gelöscht werden?`;
  }

  private async getCompanyName(developerId: string) {
    let companyName: string = '';
    try {
      if (this.isAdmin) {
        const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(developerId);
        companyName = companyInformationResponse.data.companyName as string;
      }
    } catch (e) {
      companyName = '';
    }
    return companyName;
  }

  private mapResponsePublishStateEnumToPublishState(state: PublishTypeEnum) {
    switch (state) {
      case PublishTypeEnum.Published:
        return this.publishStates.Published;
      case PublishTypeEnum.InReview:
        return this.publishStates.InReview;
      case PublishTypeEnum.Rejected:
        return this.publishStates.Rejected;
      default:
        return this.publishStates.Editing;
    }
  }

  private async loadAllCompanyNames() {

    const promises = [];
    const processedDeveloperIds: string[] = Array.from(this.developerIdToCompanyNameMap.keys());

    for (let i = 0; i < this.projects.length; i += 1) {

      const developerId: string = this.projects[i].developerId as string;

      if (developerId && !processedDeveloperIds.includes(developerId)) {
        promises.push(this.loadCompanyName(developerId));
        processedDeveloperIds.push(developerId);
      }
    }

    await Promise.all(promises);
  }

  private async loadCompanyName(developerId: string) {
    if (developerId && developerId !== '' && !this.developerIdToCompanyNameMap.has(developerId)) {
      const companyName: string = await this.getCompanyName(developerId);
      this.developerIdToCompanyNameMap.set(developerId as string, companyName);
    }
  }

  private mapRoleStringToEnumValue(role: string): Role {
    switch (role) {
      case 'User':
        return Role.USER;
      case 'Developer':
        return Role.DEVELOPER;
      case 'Admin':
        return Role.ADMIN;
      default:
        return Role.NOROLE;
    }
  }

  private editProject(id: string) {
    this.$router.push({
      name: 'ProjectEditing',
      params: {
        projectUuidProp: id,
      },
    });
  }

  private deleteProject(id: string, name: string) {
    this.deleteDialogMessage = this.getDeleteMessage(name);
    this.showDeleteDialog = true;
    this.projectToDeleteUuid = id;
  }

  private async deleteProjectConfirmed() {
    this.showDeleteDialog = false;

    if (this.projectToDeleteUuid) {
      const result = await Services.projects.deleteProjectById(this.projectToDeleteUuid);

      if (result.status === 204) {
        this.projects = this.projects.filter((p) => p.id !== this.projectToDeleteUuid);
      }

      this.projectToDeleteUuid = undefined;
      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private deleteProjectCanceled() {
    this.showDeleteDialog = false;
    this.projectToDeleteUuid = undefined;
  }

  private async searchProjects(pageNumber: number = 0, searchTerm: string, filterBy: Filter, sortBy: Option): Promise<ProjectOverviewRestDto[]> {

    try {
      const response = await Services.projects.getProjects(pageNumber, this.itemsPerPage, searchTerm, filterBy, sortBy.value.value, sortBy.value.order, this.publishStateFiltersForRequest, this.userId);
      this.currentPage = response.data.currentPage as number;
      this.totalNumberOfItems = response.data.totalItemsFound as number;
      this.totalPages = response.data.totalPages as number;
      await this.loadAllCompanyNames();

      try {
        if (searchTerm.trim()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$matomo.trackSiteSearch(searchTerm, 'project table', response.data.totalItemsFound);
        }
      } catch (e) {
        // do nothing
      }

      return response.data.projects as ProjectOverviewRestDto[];
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
      return [];
    }
  }

  private async publishStateSelectionChanged(selectedStateFilters: ('Published' | 'Rejected' | 'InReview' | 'Editing')[]) {
    this.selectedStateFilters = selectedStateFilters;
    this.currentPage = 0;
    this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async sortValueChanged() {
    this.currentPage = 0;
    this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async filterValueChanged(filterValue: Filter) {
    this.currentPage = 0;
    this.filterValue = filterValue;
    this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async searchButtonClicked(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async itemsPerPageChanged(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
  }

  private async toFirstPage() {
    if (this.currentPage > 0) {
      this.currentPage = 0;
      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async previousPage() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async nextPage() {
    if (this.currentItemEnd < this.totalNumberOfItems) {
      this.currentPage += 1;
      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

  private async toLastPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage = this.totalPages - 1;
      this.projects = await this.searchProjects(this.currentPage, this.searchTerm, this.filterValue, this.sortValue);
    }
  }

}
