























import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import Header from '@/assets/headers';
import BaseTableCard from '@/components/table/BaseTableCard.vue';
import TableCardEntry from '@/components/table/TableCardEntry.vue';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: { TableCardEntry, TextHeader, BaseTableCard },
})
export default class NewsTableCard extends Vue {
  private Header = Header;

  @Prop({ required: true })
  private state!: unknown;

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: true })
  private lastEditingUser!: string;

  @Prop({ required: true })
  private publishDate!: string;

  @Emit()
  private edit() {
    // emits event
  }

  @Emit()
  private remove() {
    // emits event
  }
}
